@keyframes cookie {
  0% {
    bottom: -100%;
    opacity: 0;
  }

  100% {
    bottom: 20px;
    opacity: 1;
  }
}

.trifecta-app_level-item_next {
  height: 140px !important;
}

.trifecta-app_content_cabinet .trifecta-app_content_cabinet_withdraw {
  width: 110% !important;
}

.trifecta-app_side-menu {
  z-index: 100;
}

.cookie-app {
  animation: cookie 0.75s ease-in-out;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 20;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 300px;
  height: fit-content;
  background: linear-gradient(285.18deg, #0D0B16 0%, #242E4C 100%);
  border-radius: 20px;
  padding: 50px 25px 20px;
  box-shadow: 5px 5px 1px 1px rgba(31, 31, 31, 0.05);

  &-back {
    position: absolute;
    right: 0;
    top: 0;
    width: 300px;
    border-top-right-radius: 20px;
  }

  &-close {
    width: fit-content;
    position: absolute;
    left: 20px;
    top: 20px;
    transition: 0.3s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  &-title {
    margin-top: 80px;
    font-size: 20px;
    font-weight: 700;
    background: linear-gradient(88.43deg, #53CDEF 0%, #467CD7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
  }

  &-text {
    margin: 30px 0;
    font-weight: 400;
    font-size: 14px;
    color: #FAFCFD;
  }

  &-btn {
    align-self: center;
    width: fit-content;
    padding: 15px 50px;
    font-weight: 500;
    font-size: 16px;
    background: linear-gradient(92.97deg, #0F2467 0%, #1D5CAC 100%);
    border-radius: 50px;
    color: #FAFCFD;
    transition: 0.3s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

.trifecta-app {
  font-family: 'aqum';
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(127.61deg, #5889E7 0%, #A9F0FF 87.38%);


  &_wrapper {
    position: relative;
  }

  &_burger {
    display: none;
    top: 72px;
    right: 28px;
    z-index: 1000;
    position: absolute;
  }

  &_loading {
    color: #FFFFFF;
    font-size: 32px;
    position: absolute;
    top: 30%;
    left: calc(340px + 30%);
    transform: translate(50%, -70%);
  }

  .level {
    background: linear-gradient(87.57deg, #3F87F6 0%, #4CE9C1 94.44%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &_side-menu {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    height: 100%;
    color: #7897FA;
    display: flex;
    flex-direction: column;
    width: 320px;
    background: #171A2F;
    border-radius: 50px 0 0 50px;
    padding: 75px 60px;

    &_partner {
      background: linear-gradient(92.97deg, #0F2467 0%, #1D5CAC 100%);
      font-weight: 700;
      font-size: 14px;
      color: #FAFCFD;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 32px;
      padding: 4px 24px;
      margin-top: 18px;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    &_logo {
      margin: 0 auto 50px;
      width: fit-content;
    }

    &_item {
      display: flex;
      flex-direction: column;

      &-sub-item.active {
        color: #ffffff;
        font-weight: 700;
      }

      &-sub-item.disabled {
        cursor: not-allowed;
        color: #ccc !important;
      }

      &-sub-item {
        position: relative;
        color: #7897FA;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 12px 0;
        cursor: pointer;

        &_badge {
          position: absolute;
          background: #30387A;
          color: #FAFCFD;
          width: 12px;
          text-align: center;
          line-height: 12px;
          border-radius: 50%;
          padding: 5px;
          height: 12px;
          font-size: 12px;
          right: 0;
        }

        img {
          margin-right: 12px;
        }
      }

      &-title {
        font-size: 36px;
        font-weight: 500;
        color: #fff;
        margin: 60px 0 20px;
      }
    }

    &_profile {
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      color: #ffffff;

      &_text {
        width: 50%;
        margin-left: 16px;
        font-size: 18px;
        font-weight: 500;
      }

      &_image {
        background-size: cover;
        background-position: center center;
        border-radius: 50%;
        width: 48px;
        height: 48px;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &_level {
    &-item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      &_overall {
        position: relative;
        color: #ffffff;
        border: 25px solid transparent;
        border-radius: 50%;
        background: #212735;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        z-index: 10;

        &-container {
          box-sizing: border-box !important;
          position: absolute;
          width: 244px;
          height: 244px;
          top: 0;
          transform: translate(-50%, 18px);
          z-index: 1;
          left: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          background: #313751;;
          border-radius: 50%;
          padding: 44px 40px;
          color: #FAFCFD !important;
        }

        &:before {
          z-index: -10;
          content: '';
          position: absolute;
          top: 0; right: 0; bottom: 0; left: 0;
          margin: -25px; /* !importanté */
          border-radius: inherit; /* !importanté */
          background: conic-gradient(from 180deg at 50% 50%, #48C5D6 0deg, #28AEB7 45deg, rgba(148, 115, 220, 0.994565) 120deg, rgba(57, 79, 194, 0.994565) 270deg, rgba(81, 103, 215, 0) 360deg, rgba(33, 39, 53, 0) 360deg, #478C88 360deg, #48C5D6 0deg);
        }

        &_title {
          margin: 24px 0;
          font-size: 28px;
          font-weight: 500;
        }
      }

      &_next {
        width: 240px;
        height: 100px;
        color: #ffffff;
        margin-top: 50px;
        padding: 25px 50px;
        background: #252C40;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &_percent {
          font-size: 36px;
          text-align: right;
          font-weight: 500;
        }

        &_title {
          margin-bottom: 16px;
          background: linear-gradient(87.57deg, #3F87F6 0%, #4CE9C1 94.44%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  &_package {
    padding: 25px;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #252C40;
    border-radius: 30px;
    font-weight: 700;
    font-size: 32px;
    line-height: 24px;
    color: #ffffff;
    transition: 0.3s;
    margin-right: 12px;
    margin-bottom: 12px;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    img {
      width: 120px;
      height: 120px;
    }
  }

  &_withdraw_card {
    width: fit-content;
    height: 120px;
    background: #252C40;
    padding: 24px 48px;
    border-radius: 30px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;

    &_title {
      margin: 0 0 12px;
      background: linear-gradient(87.57deg, #3F87F6 0%, #4CE9C1 94.44%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &_sub-amount {
      font-size: 14px;
    }

    &_withdraw {
      margin-top: 16px;
      padding: 4px 16px;
      width: fit-content;
      border-radius: 40px;
      background: #313751;
      color: #3F87F6;
      margin-left: auto;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }

  &_content {
    font-family: 'aqum';
    position: relative !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 50px 50px 50px 0;
    background: #13162C;
    box-shadow: 0px 0px 150px rgba(19, 22, 44, 0.7);
    border-radius: 50px;
    max-width: 1440px;
    width: 100%;
    margin: 50px 25px;
    min-height: 1200px;

    &_cabinet {
      width: fit-content;
      margin-left: 42%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      &_level {
        margin: 30px 0 30px 0;
        width: 95%;
        padding: 50px 70px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #171A2F;
        border-radius: 30px;

        &-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          &_overall {
            color: #ffffff;
            border: 25px solid #48C5D6;
            background: #313751;
          }
        }
      }

      &_package {
        margin-right: 20px;
      }

      &_packages {
        box-sizing: border-box;
        width: 100%;
        padding: 20px 20px;
        border-radius: 30px;
        background: #171A2F;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &_withdraw {
        box-sizing: border-box;
        width: 100%;
        padding: 25px;
        border-radius: 30px;
        background: #171A2F;
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;
      }
    }
  }
}

@media (max-width: 1400px) {
  .trifecta-app {
    width: 100%;
  }

  .trifecta-app_burger {
    display: initial;
  }

  .trifecta-app_content_cabinet {
    box-sizing: border-box;
    width: 100% !important;
    margin: 0;
  }
}

@media (max-width: 1020px) {
  .trifecta-app_content {
    box-sizing: border-box !important;
    justify-content: center;
  }

  .trifecta-app_content_cabinet_packages {
    box-sizing: border-box;
    width: 70%;
    margin-left: 114px;
  }

  .trifecta-app_content_cabinet_withdraw {
    box-sizing: border-box;
    flex-direction: column;
    width: 70%;
    margin-left: 50px;
    align-self: center;
    justify-self: center;

    .trifecta-app_withdraw_card {
      margin: 20px 0;
    }
  }

  .trifecta-app_content_cabinet_level {
    box-sizing: border-box;
    flex-direction: column;
    width: 70%;
    margin-left: 114px;

    .trifecta-app_level-item {
      margin: 20px 0;
    }
  }
}

@media (max-width: 720px) {
  .trifecta-app_content_cabinet_packages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 0 16px 16px;
  }

  .trifecta-app_content_cabinet .trifecta-app_content_cabinet_withdraw {
    margin-left: 48px;
  }

  .trifecta-app_withdraw_card {
    max-width: 190px !important;
    padding-bottom: 48px;
  }

  .trifecta-app_wrapper {
    width: 100%;
  }

  .trifecta-app_content {
    width: 100%;
    box-sizing: border-box !important;
  }

  .trifecta-app_content_cabinet_level {
    width: 100%;
    margin-left: 24px;
  }

  .trifecta-app_content_cabinet_packages {
    width: 100%;
    margin-left: 24px;
  }

  .trifecta-app_burger {
    display: initial;
  }

  .trifecta-app_side-menu {
    width: 100%;
    transition: 0.3s ease;
    left: 0;
    top: 0;
    z-index: 100;
  }

  .trifecta-app_level-item_next {
    padding: 16px;
    font-size: 12px;
  }
}

.menu_closed {
  opacity: 0;
  left: -450px;
  transition: 0.3s;
  padding: 0;
}
