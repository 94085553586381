button {
  outline: none;
  border: none;
}

.header-register-btn {
  background: linear-gradient(157.26deg, #253B79 10.6%, #4486EB 91.3%);
  /* Ps3D */

  box-shadow: 4px 4px 4px rgba(0, 33, 74, 0.25), inset -4px -4px 4px rgba(0, 33, 74, 0.25), inset 4px 4px 4px rgba(255, 255, 255, 0.25);
  border-radius: 100px
}

.header-login-btn {
  background: linear-gradient(87.57deg, #3FCAF6 0%, #4CE9C1 94.44%);
  /* Ps3D */

  box-shadow: 4px 4px 4px rgba(0, 33, 74, 0.25), inset -4px -4px 4px rgba(0, 33, 74, 0.25), inset 4px 4px 4px rgba(255, 255, 255, 0.25);
  border-radius: 100px;
}

.header {
  font-family: 'aqum';
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  z-index: 10;

  &_logo-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &_menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 48px;

    &-item {
      color: #005395;
      font-weight: 700;
      margin: 0 12px;
      font-size: 21px;
      text-transform: uppercase;
      transition: .3s;
      font-family: 'aqum', sans-serif;

      &:hover {
        cursor: pointer;
        transform: translateY(-2px);
      }
    }
  }

  &_controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      padding: 12.5px 32px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0 12px;
      font-size: 20px;
      color: #fff;
      transition: .3s;
      font-family: 'aqum', sans-serif;

      span {
        box-sizing: border-box;
        margin-left: 10px;
        padding-bottom: 4px;
      }
    }
  }
}

@media(max-width: 1200px) {
  .header {
    width: 100%;

    &_logo-menu {
      flex-direction: column;
    }

    &_menu-item {
      margin-top: 12px;
      font-size: 16px;
    }

    &_controls {
      button {
        font-size: 16px;

        span {
          padding-bottom: 2px;
        }

        img {
          width: 12px;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .header {
    flex-direction: column;

    &_menu {
      margin: 0;
    }

    &_controls {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
    }
  }
}

@media (max-width: 960px) {
  .header_wrapper {
    box-shadow: none !important;
  }
}

@media (max-width: 425px) {
  .header {
    box-sizing: border-box;
    padding: 20px 12px;

    &_menu-item {
      margin-top: 12px;
      font-size: 10px;
    }

    &_controls {
      button {
        font-size: 14px;
        padding: 8px 24px;
      }
    }
  }
}