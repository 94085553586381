.newbie {
  height: 100%;
  width: 100%;
  border-radius: 30px;

  &_material-title {
    color: #FAFCFD;
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-self: flex-start;
    font-size: 24px;
    margin-bottom: 32px;
  }

  &_crypto-material {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }

  .tg-materials-link {
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  &_item {
    object-fit: contain;
    padding: 24px;
    margin-bottom: 24px;
    background: #171A2F;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    transition: 0.3s;
    justify-content: space-between;

    &_videos {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;

      video {
        border-radius: 12px;
      }
    }

    img {
      object-fit: contain;
      height: 200px;
    }

    &_title {
      line-height: 20px;
      background: linear-gradient(87.57deg, #3F87F6 0%, #4CE9C1 94.44%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      font-size: 28px;
    }

    &_text {
      color: #FFFFFF;

      &_content {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        margin-top: 20px;
        font-weight: 700;
        font-size: 16px;

        &_image {
          width: 40px;
          height: 40px !important;
        }
      }
    }
  }

  &_wrapper {
    margin: 0 0 50px 360px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
  }
}

@media (max-width: 1400px) {
  .newbie_wrapper {
    margin: 0 0 50px 48px;
  }
}

@media (max-width: 600px) {
  .newbie_item {
    padding: 24px 16px;
    flex-direction: column;

    &_videos {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;

      video {
        object-fit: contain;
        width: 100%;
        height: auto;
        border-radius: 16px;
      }
    }
  }

  .newbie_item_text {
    margin: 32px 0 0;
  }

  .newbie_item_title {
    font-size: 16px;
  }

  .newbie_item_text_content {
    font-size: 12px;
  }
}
