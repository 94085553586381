.help {
  padding: 50px;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background: #171A2F;
  border-radius: 30px;

  &_content_links_item {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fafbfd;
    border-radius: 50%;
    width: 48px;
    height: 48px;

    img {
      height: 22px !important;
      width: 24px !important;
    }
  }

  .closed {
    opacity: 0;
    padding: 30px 38px 50px 20px;
    height: 0;
    width: 90%;
    transition: .3s;
  }

  .opened {
    transition: .3s;
  }

  .pointer-closed {
    transition: 0.3s;
  }

  .pointer-opened {
    transition: 0.3s;
    transform: rotate(180deg);
  }

  &_faq {
    margin-top: 50px;
    width: 100%;

    &_item {
      z-index: 1;
      position: relative;
      margin-top: 25px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;


      &_content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 50px;
        width: 100%;
        background: #252C40;
        border-radius: 20px;
      }

      &_text {
        transition: .3s;
        width: 90%;
        top: 50px;
        left: 0;
        z-index: -1;
        font-weight: 700;
        font-size: 24px;
        color: #ffffff;
        padding: 70px 38px 50px 50px;
        background: #323B55;
        border-radius: 20px;
        position: absolute;
      }

      img {
        z-index: 2;
      }

      span {
        z-index: 2;
        font-weight: 700;
        font-size: 24px;
        background: linear-gradient(87.57deg, #3F87F6 0%, #4CE9C1 94.44%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &_title {
      padding: 20px 50px;
      background: #252C40;
      border-radius: 20px;

      span {
        font-size: 48px;
        line-height: 60px;
        background: linear-gradient(87.57deg, #3F87F6 0%, #4CE9C1 94.44%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &_card_tg_img {
    width: 56px !important;
    height: 56px !important;;
  }

  &_back {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &_card {
    padding: 25px 50px;
    position: relative;
    background: #252C40;
    border-radius: 20px;

    &_tg {
      margin-top: 30px;
    }

    &_text {
      margin-top: 30px;
      color: #ffffff;
      font-weight: 600;
      font-size: 24px;

      &_title {
        margin-top: 30px;
      }

      &_item {
        margin: 8px 0;
        font-weight: 400;
      }
    }

    &_title {
      width: 80%;
      font-weight: 700;
      font-size: 24px;
      background: linear-gradient(87.57deg, #3F87F6 0%, #4CE9C1 94.44%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img:first-child {
      height: 240px;
      width: 240px;
    }
  }

  &_wrapper {
    width: 100%;
    margin: 50px 0 50px 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
}

@media (max-width: 1400px) {
  .help_wrapper {
    margin: 50px 0 50px 48px;
  }
}

@media (max-width: 600px) {
  .help {
    width: 100%;
    padding: 64px 32px 0;
  }

  .help_header_info {
    display: none;
  }

  .help_header {
    font-size: 16px;
    width: 90%;
    margin-top: -32px;
    align-self: center;
    flex-direction: column;
  }

  .help_card_title {
    font-size: 16px;
  }

  .help_card_text {
    font-size: 14px;
  }

  .help_card {
    width: 100%;
    padding: 24px 16px;
  }

  .help_card img {
    width: 30%;
  }

  .help_faq {
    font-size: 16px !important;
  }

  .help_faq_title {
    padding: 12px 24px;
  }

  .help_faq_title span {
    font-size: 18px;
  }

  .help_faq_item span {
    font-size: 12px;
  }

  .help_faq_item_content {
    width: 100%;
    padding: 12px 24px;

    img {
      width: 16px;
    }
  }

  .help_faq_item_text.opened {
    font-size: 12px;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
  }
}