.progress {
  box-sizing: border-box;
  margin-top: 50px;
  height: 100%;
  padding: 50px;
  width: 100%;
  background: #171A2F;
  border-radius: 30px;

  &_bar {
    margin: 32px 0 16px;
    &_title {
      margin: 5px 0;
      background: linear-gradient(87.57deg, #3F87F6 0%, #4CE9C1 94.44%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &_content {
      position: relative;
      width: 70%;
      height: 20px;
      background: #C3DEEC;
      border-radius: 20px;

      &_progress {
        position: absolute;
        width: 100%;
        height: 20px;
        background: linear-gradient(88.43deg, #53CDEF 0%, #467CD7 100%);
        border-radius: 20px;
      }
    }

    &_subtitle {
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      margin: 4px 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #FAFCFD;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;

    &_item {
      margin-bottom: 70px;
      display: flex;
      flex-direction: column;

      &_title {
        font-weight: 700;
        font-size: 32px;
        color: #FAFCFD;
      }
    }
  }

  &_wrapper {
    margin: 0 0 50px 28%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
  }

  &_bar_content_progress {
    max-width: 100% !important;
  }
}

@media (max-width: 1400px) {
  .progress_wrapper {
    margin: 0 0 50px 72px;
  }
}

@media (max-width: 600px) {
  .progress {
    padding: 24px 16px;
    width: 100%;
    margin-left: -20px;
  }

  .progress_content {
    width: 100%;
    font-size: 14px !important;
  }

  .progress_content_item_title {
    font-size: 16px;
  }

  .progress_bar_content {
    width: 100%;
  }

  .progress_bar_subtitle {
    font-size: 12px;
  }
}
