.trifecta-app-footer {
  font-family: "Lato", sans-serif;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1440px !important;
  background: #13162C;
  box-shadow: 0 0 150px rgba(19, 22, 44, 0.7);
  border-radius: 50px;
  color: #FFFFFF;
  padding: 50px 100px;
  height: 100%;
  margin: 25px 0 50px;

  &_wrapper {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &_logo {
    margin: 0 0 40px;
  }

  &_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 450px;

    &_mg {
      margin-top: 10px;
    }

    &_links {
      margin: 50px 0 30px;
      display: flex;
      flex-direction: row;

      &_item {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #FAFCFD;
        border-radius: 50%;
        padding: 12px;
        margin: 0 4px;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:hover {
          cursor: pointer;
        }

        img {
          width: 16px !important;
        }
      }
    }

    &_text {
      color: #FAFCFD;
      opacity: 0.5;

      span {
        font-size: 14px;
        margin-right: 24px;
        transition: 0.3s;

        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }

    img {
      width: fit-content;
    }
  }

  &_copyright {
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
}

@media (max-width: 768px) {
  .trifecta-app-footer_copyright {
    display: none;
  }

  .trifecta-app-footer {
    box-sizing: border-box;
    flex-direction: row;
  }
}

@media (max-width: 600px) {
 .trifecta-app-footer {
   box-sizing: border-box;
    padding: 24px;
   width: 100%;
   align-items: center;
   flex-direction: row;
 }

  .trifecta-app-footer_content_links {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .trifecta-app-footer_content_text {
    text-align: center;
  }

  .trifecta-app-footer_content_text span {
    margin: 12px 0 32px !important;
  }

  .trifecta-app-footer_content {
    display: flex;
    justify-content: center;
    width: 100% !important;
    font-size: 12px;

    img {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
    }
  }

  .trifecta-app-footer_partners {
    margin-top: 32px;

    img {
      width: 100%;
    }
  }
}