@keyframes loyal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
    width: fit-content;
  }
}

.person-card.expanded {
  animation: loyal 0.5s ease-in-out;
  display: flex;
  flex-direction: row;
}

.person-card {
  box-sizing: border-box !important;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 40px 25px 25px;
  background: #252C40;
  border-radius: 20px;
  font-weight: 700;
  transition: 0.5s;
  animation: loyal 0.5 ease-in-out;
  margin: 8px;
  min-width: 320px;
  width: fit-content;
  height: 180px;

  &_expand.expanded {
    transform: rotate(180deg);
  }

  &_loading {
    color: #FFFFFF;
  }

  &_wrapper {
    margin: 0 4px;
    width: 320px;
  }

  &_additional {
    box-sizing: border-box !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &_info {
      margin: 0 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      color: #ffffff;
    }

    .person-card_text.colored {
      font-size: 14px;
      width: 90px;
    }
  }

  &_expand {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }

  &_text {
    width: 100%;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
  }

  &_text.colored {
    background: linear-gradient(87.57deg, #3F87F6 0%, #4CE9C1 94.44%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 28px;
    margin-bottom: 24px;
  }

  &_text.colored.verified {
    background: linear-gradient(87.57deg, #00ff95 0%, #00ff48 90.44%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 28px;
    margin-bottom: 24px;
  }

  &_wrapper.expanded {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .person-card_text {
    font-size: 12px;
  }

  .person-card_text.colored {
    font-size: 16px;
  }
}