.first-grad {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  background: linear-gradient(88.43deg, #53CDEF 0%, #467CD7 100%);
}

.second-grad {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  background: linear-gradient(88.43deg, #53CDEF 0%, #467CD7 100%);
}

.third-grad {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  background: linear-gradient(88.43deg, #53CDEF 0%, #467CD7 100%);
}

.main {
  margin: 40px 0 0;
  width: 1200px;
  display: flex;
  flex-direction: column;

  .swiper {
    background: transparent;
    width: 1200px;
  }

  .swiper-scrollbar {
    left: 50%;
    transform: translateX(-50%);
    width: 30%;
    height: 20px;
    background-color: #C3DEEC;
    opacity: 1 !important;
  }

  .swiper-scrollbar-drag {
    width: 66.5% !important;
    background: linear-gradient(88.43deg, #53CDEF 0%, #467CD7 100%);
    border-radius: 20px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 80px;
  }

  .swiper-slide img {
    display: block;
    width: 80%;
    height: 80%;
    object-fit: cover;
  }


  &_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &_works {
    font-family: 'aqum';
    margin: 160px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_more {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background: linear-gradient(92.97deg, #0F2467 0%, #1D5CAC 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 24px;
      position: absolute;
      bottom: -80px;
      right: -60px;
      transition: .3s;

      img {
        margin-left: 24px;
        height: 21px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    .how-it-work-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #005395;

      &-line {
        height: 80px;
        margin: 0 16px;
      }

      &-title {
        font-size: 28px;
        font-weight: 700;
        margin-top: 24px;
      }

      &-text {
        text-align: center;
        font-size: 18px;
        max-width: 230px;
        font-weight: 500;
        margin-top: 18px;
      }
    }

    &_cards {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &_title {
      font-family: "Lato", sans-serif;
      margin-bottom: 70px;
      font-weight: 700;
      background: linear-gradient(88.43deg, #53CDEF 0%, #467CD7 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-transform: uppercase;
      font-size: 110px;
    }
  }

  &_packages {
    align-self: center;
    width: fit-content;
    position: relative;
    margin: 120px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &-title {
      position: absolute;
      right: -260px;
      z-index: -10;
      top: 32%;
      font-weight: 700;
      transform: rotate(90deg);

      text-transform: uppercase;
      background: -webkit-linear-gradient(88.43deg, #53CDEF 0%, #467CD7 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 132px;
    }
  }

  &_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &_left {
    display: flex;
    flex-direction: column;
    width: 75%;
  }

  &_title {
    line-height: 101px;
    font-size: 80px;
    font-weight: 700;
    background: -webkit-linear-gradient(88.43deg, #FF82F4 0%, #53CDEF 100%);;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &_text {
    font-weight: 700;
    margin: 32px 0 64px;
    font-size: 20px;
    background: -webkit-linear-gradient(88.43deg, #53CDEF 0%, #467CD7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &_btn {
    font-family: 'aqum', sans-serif;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-transform: uppercase;
    font-size: 16px;
    width: fit-content;
    padding: 16px 32px;
    background: linear-gradient(91.97deg, #049CE4 0.3%, #80ECDF 99.83%);
    box-shadow: 6px 6px 10px rgba(0, 33, 74, 0.25), inset -6px -6px 10px rgba(0, 33, 74, 0.25), inset 6px 6px 10px rgba(255, 255, 255, 0.4);
    border-radius: 100px;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  &_right {
    width: 50%;
  }
}

@media (max-width: 1200px) {
  .main_title {
    line-height: 72px;
    font-size: 60px;
  }

  .main_works_title {
    font-size: 60px;
    text-align: center;
  }

  .main_works_cards {
    flex-direction: column;
  }

  .main_works .how-it-work-card-line {
    margin: 24px 0 12px;
  }

  .main_works_more {
    right: 12px;
  }

  .main_wrapper {
    box-sizing: border-box !important;
    padding: 0 16px;
  }

  .main {
    width: 100%;

    &_right {
      box-sizing: border-box !important;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 480px;
      margin-bottom: -40px;
    }

    &_left {
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    &_content {
      width: 100%;
      flex-direction: column;
    }

    &_wrapper {
      padding: 0 !important;
    }
  }

  .goal {
    width: 90% !important;
    flex-direction: column !important;
  }

  .goal_item:first-child {
    margin-left: 0 !important;
  }

  .goal_wrapper {
    background-position: 0 75% !important;
  }
}

@media (max-width: 960px) {
  .goal_wrapper {
    background-position: 0 100% !important;
    background-size: 90% !important;
  }

  .how-it-work-card-line {
    transform: rotate(90deg);
  }

  .goal_item:first-child {
    flex-direction: column-reverse;
  }

  .goal_item:last-child {
    margin-bottom: 340px !important;
  }

  .main {
    box-sizing: border-box;

    &_text {
      display: none;
    }

    &_right {
      margin-top: -100px;
    }

    &_left {
      font-size: 16px;
    }
  }

  .main_content {
    flex-direction: column-reverse;
  }

  .main_title {
    text-align: center;
    line-height: 40px;
    font-size: 24px;
    margin: 0 0 40px;
  }

  .main_works {
    width: 100%;
    box-sizing: border-box !important;
    margin-top: 40px;
  }

  .how-it-work-card {
    img {
      max-width: 100%;
    }
  }

  .footer_form div:last-child {
    margin-top: 4px;
    width: 48px !important;
    height: 48px !important;
  }

  .footer_info_links span {
    font-size: 12px !important;
  }

  .footer_content_navigation-item {
    display: flex;
    flex-direction: column;
  }

  .footer_content_left-text {
    font-size: 12px !important;
  }

  .goal_item-text {
    font-size: 18px !important;
  }

  .goal_item-title {
    margin: 12px 0;
    font-size: 20px !important;
  }

  .goal_item {
    box-sizing: border-box;
    font-size: 16px;
    margin: 0 0 48px;
    img {
      display: none;
      width: 80%;
    }
    width: 100%;
  }

  .main_works_title {
    font-size: 48px;
  }
}

