@keyframes loyalLeft {
  0% {
    width: 560px;
    margin-left: -400px;
    margin-right: 490px;
  }

  100% {
    width: 560px;
  }
}

@keyframes loyalRight {
  0% {
    width: 560px;
    margin-left: 400px;
    margin-right: -300px;
  }

  100% {
    width: 560px;
  }
}

@keyframes loyal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.products {
  animation: loyal 0.5;
  display: flex;
  flex-direction: column;
  width: 1200px;
  margin: 40px 0 80px;

  &_item {
    position: relative;
    margin: 40px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &_box {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      height: 480px;
      padding: 75px 100px 50px;
      color: #FFF;
      background: linear-gradient(88.43deg, #53CDEF 0%, #467CD7 100%);
      box-shadow: inset -4px -4px 4px rgba(0, 33, 74, 0.25), inset 4px 4px 4px rgba(255, 255, 255, 0.25);
      border-radius: 100px;

      &_event-back {
        position: absolute;
        right: -50px;
        bottom: 50px;
        width: 100%;
      }

      .products_bubbles {
        position: absolute;
        z-index: -5;
        width: 100%;
        height: 100%;
      }

      &:nth-child(2n) {
        margin-right: 0;
        margin-left: 100px;
        animation: loyalRight 1s ease;
      }

      &:nth-child(2n + 1) {
        animation: loyalLeft 1s ease;
        margin-left: 0;
        margin-right: 90px !important;

        .products_bubbles {
          position: absolute;
          z-index: -5;
          left: -110px;
          width: 100%;
          height: 100%;
        }
      }

      &_title {
        font-weight: 700;
        font-size: 58px;
        margin-bottom: 24px;
      }

      &_text {
        text-align: left;
      }

      &_more {
        font-family: 'aqum';
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: flex-end;
        margin-top: 30px;
        text-align: right;
        font-size: 28px;
        font-weight: 700;
        text-transform: uppercase;

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 27px !important;
          padding-bottom: 2px;
        }

        img {
          margin-left: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 27px !important;
          width: 50px !important;
        }
      }
    }

    img {
      width: 45%;
    }
  }

  &_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}

@media (max-width: 1200px) {
  .products {
    width: 100%;

    &_wrapper {
      padding: 0 16px;
      box-sizing: border-box;
    }
  }
}

@media (max-width: 1020px) {
  .products_item {
    flex-direction: column;
  }

  .products_item_box .products_bubbles {
    display: none;
  }

  .products_item_box:nth-child(2n+1) {
    margin: 0 !important;
    animation: none;
  }

  .products_item_box:nth-child(2n) {
    margin: 0 !important;
    animation: none;
  }

  .products_item img {
    width: 50%;
  }

  .products_item_box_event-back {
    right: 0;
  }
}

@media (max-width: 960px) {
  .products_item img {
    width: 100%;
    margin: 20px 0;
  }

  .products_item_box_event-back {
    display: none;
  }

  .products_item_box {
    border-radius: 48px;
    height: fit-content;
    padding: 50px;
  }

  .products_item_box_title {
    font-size: 20px;
  }

  .products_item_box_text {
    font-size: 12px;
    height: 100%;
  }

  .products_item:nth-child(2n) {
    flex-direction: column-reverse;
  }

  .products {
    margin-top: 0;
  }
}