@keyframes loyalLog {
  0% {
    opacity: 0;
    bottom: -100%;
  }

  100% {
    opacity: 1;
    bottom: 0;
  }
}

select {
  appearance: none;
  background-repeat: no-repeat !important;
  background-position: 100% 100%;
}

.login {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 900px;
  height: auto;
  background: #13162C;
  box-shadow: 0 0 150px rgba(19, 22, 44, 0.7);
  border-radius: 50px;
  padding: 50px 200px;

  &_login-container {
    position: relative;
    width: 100%;

    img {
      position: absolute;
      right: 34px;
      top: 34%;
      transform: translateY(-50%);
      width: 24px;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  &_agreement {
    width: 100%;
    display: flex;
    flex-direction: column;

    &_item {
      margin: 4px 0 0;
      height: fit-content;
      width: 100%;
      color: #fff;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      input {
        background: #0f2467;
        box-sizing: border-box;
        display: flex;
        align-self: center;
        justify-self: center;
        width: 16px !important;
        height: 16px !important;
        margin: 0 !important;
      }

      p {
        font-size: 14px;
        line-height: 14px;
        margin-left: 12px;

        a {
          font-style: italic;
          font-weight: 700;
          transition: 0.3s;

          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }
      }
    }
  }

  &_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    input {
      width: 18% !important;
    }
  }

  &_footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    input.referral {
      background: linear-gradient(92.97deg, #0F2467 0%, #1D5CAC 100%);
      width: 48%;
      padding: 16px;
      font-size: 14px;
      height: 12px;
      color: #fff;
      margin: 0;
    }

    select {
      background-color: #0F2467;
      border-radius: 10px;
      border: none;
      padding: 12px;
      width: 40%;
      height: 48px;
      color: #fff;
      background-size: 14px 8px;
      background-position-y: calc(50% + 1px);
      background-position-x: calc(100% - 14px);
    }
  }

  &_trifecta {
    margin-top: 30px;
  }

  &_btn {
    margin-top: 20px;
    background: linear-gradient(92.97deg, #0F2467 0%, #1D5CAC 100%);
    border-radius: 32px;
    color: #fff;
    padding: 12px 36px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 18px;
    width: 100%;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.8;
    }
  }

  .google-btn {
    text-transform: initial;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #252C40;

    img {
      margin: 0 12px 3px;
    }
  }

  input {
    background: #FAFCFD;
    border-radius: 10px;
    border: none;
    outline: none;
    padding: 12px 36px;
    margin-bottom: 20px;
    width: calc(100% - 72px);
    font-size: 16px;
  }

  &_title {
    color: #FFFFFF;
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 12px;
  }

  &_wrapper {
    animation: loyalLog 0.75s;
    position: relative;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    height: fit-content;
    background: linear-gradient(127.61deg, #6C90D7 0%, #A9F0FF 87.38%);
  }
}

@media (max-width: 1200px) {
  .login {
    width: 100%;

    &_wrapper {
      box-sizing: border-box;
      padding: 0 16px;
    }
  }
}


@media (max-width: 960px) {
  .login {
    width: 100%;
    padding: 24px 48px;

    &_wrapper {
      box-sizing: border-box;
      padding: 0 16px;
    }
  }

  .login_header {
    padding: 0 48px !important;
    width: 100%;
    flex-direction: column;
  }

  .login_header input {
    width: calc(100% - 72px) !important;
  }

  .login_btn {
    font-size: 14px !important;
  }
}