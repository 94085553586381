.withdraw {
  box-sizing: border-box;
  overflow: scroll;
  margin-top: 50px;
  height: 100%;
  padding: 50px;
  margin-left: -50px;
  width: 100%;
  background: #171A2F;
  border-radius: 30px;

  &_table-empty {
    width: 100%;
  }

  &_table {
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    background: #252C40;
    border-radius: 20px;
    margin-top: 24px;

    &_item {
      flex-direction: row;
      display: grid;
      grid-template-columns: 40% 20% 20% 20%;
      margin: 16px 0;
    }

    &_header {
      font-size: 14px;
      display: grid;
      grid-template-columns: 40% 20% 20% 20%;
      color: #898B96;
      margin: 20px 0;
    }
  }

  &_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &_title {
      font-size: 28px;
      font-weight: 700;
      color: #FFF;
      margin-bottom: 8px;

      p {
        font-size: 14px;
        margin-bottom: 0;
        color: rgba(250, 252, 253, 0.8);
      }
    }

    &_controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-self: flex-end;

      div {
        margin-left: 30px;

        select {
          color: #ffffff;
          background: #252C40;
          border-radius: 20px;
          padding: 4px 12px;
          width: 120px;
        }

        label {
          margin-right: 12px;
          color: rgba(250, 252, 253, 0.5);
          font-weight: 700;
          font-size: 14px;
        }
      }
    }
  }

  &_wrapper {
    margin: 0 0 50px 28%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 10;


    .trifecta-app_content_cabinet_withdraw {
      width: 105% !important;
    }

  }
}

@media (max-width: 1400px) {
  .withdraw_wrapper {
    margin: 0 0 50px 72px;
  }

  .withdraw {
    overflow: scroll;
  }
}

@media (max-width: 600px) {
  .withdraw {
    margin-left: -24px;
    padding: 12px;
    font-size: 10px !important;
  }

  .withdraw_table {
    width: 150% !important;
    padding: 12px 18px;
    overflow: scroll;
    font-size: 8px;
  }

  .withdraw_table_header {
    font-size: 10px !important;
  }

  .withdraw_header_title {
    padding: 12px;
    font-size: 8px;
  }

  .withdraw_header_controls {
    select {
      width: 80px !important;
    }
  }

  .withdraw_header_controls div {
    margin: 0 4px !important;
  }

  .trifecta-app_content_cabinet_withdraw {
    margin: 0 0 0 -24px;
    width: 100%;
  }
}
