.settings {
  box-sizing: border-box;

  .upload_hint {
    margin-top: 12px;
    font-size: 12px;
  }

  .passport_upload {
    width: fit-content;
    max-width: 800px;
    display: flex;
    flex-direction: row;

    div {
      position: relative;
      width: 100%;
      margin: 0 8px !important;

      label {
        font-size: 14px;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        height: 100%;
        width: 100%;
      }

      img {
        margin-top: 8px;
        width: 100%;
      }
    }
  }

  button:disabled {
    opacity: 0.8;
    cursor: not-allowed;

    &:hover {
      opacity: 0.8;
    }
  }

  &_profile_photo {
    display: flex;
    align-items: center;
    position: relative;
    height: 360px;
    width: 360px;

    &_image {
      width: 90%;
      height: 90%;
      background-size: 100% 100%;
      background-position: center;
      border-radius: 50%;
    }

    img {
      object-fit: contain !important;
      height: 100%;
      width: 100%;
      position: absolute;
    }

    input {
      opacity: 0;
      height: 100%;
      width: 120%;
      border: none !important;
      border-radius: 100% !important;
      position: absolute;
    }
  }

  .upload-photo-input {
    position: absolute;
    height: 230px;
    width: 360px;
    opacity: 0;
    cursor: pointer;
  }

  select option {
    background: #0f2467 !important;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 1) !important;
  }

  &_documents {
    display: flex;
    flex-direction: column;

    &_list {
      display: flex;
      flex-direction: column;

      img {
        margin-top: 12px;
        border-radius: 12px;
        background: #252C40;
        width: 30%;
      }

      &_item {
        display: flex;
        flex-direction: row;
        width: 100%;
        font-size: 16px !important;
        margin: 8px 0 !important;
        background: #252C40;
        padding: 12px;
        border-radius: 12px !important;
      }
    }

    .settings_btn {
      align-self: flex-end;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    &_content {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 0 !important;
    }

    &_item_left {
      margin-top: 0 !important;
      width: 45%;
    }

    &_item_right {
      margin-top: 0 !important;
      margin-left: 10%;
      width: 45%;
    }

    &_item {
      padding: 50px;
      background: #252C40;
      border-radius: 20px;
    }

    &_header {
      margin-top: 0 !important;

      select {
        border: none;
        margin-right: 100px;
        padding: 16px 32px;
        color: #ffffff;
        background: linear-gradient(92.97deg, #0F2467 0%, #1D5CAC 100%);
        border-radius: 10px;

        option {
          background: linear-gradient(92.97deg, #0F2467 0%, #1D5CAC 100%);
        }
      }
    }

    &_title {
      margin-top: 0 !important;
    }

    div {
      margin-top: 50px;
      color: #ffffff;
      font-weight: 700;
      font-size: 24px;
    }
  }

  &_profile_item {
    margin-top: 30px;
  }

  &_security_btn {
    width: fit-content;
    padding: 12px 30px;
    color: #ffffff;
    background: linear-gradient(88.43deg, #53CDEF 0%, #467CD7 100%);
    border-radius: 32px;
    transition: 0.3s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  &_btn {
    margin-top: 30px;
    width: fit-content;
    padding: 12px 30px;
    color: #ffffff;
    background: linear-gradient(88.43deg, #53CDEF 0%, #467CD7 100%);
    border-radius: 32px;
    transition: 0.3s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  input {
    border-radius: 10px;
    background: none;
    padding: 8px 12px;
    border: 1px solid #53CDEF;
    color: #ffffff;
  }

  label {
    margin-bottom: 12px;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  margin-top: 50px;
  height: 100%;
  padding: 50px;
  width: 100%;
  background: #171A2F;
  border-radius: 30px;

  &_title {
    color: #ffffff;
    font-weight: 700;
    font-size: 32px;
  }

  &_sections {
    margin: 50px 0 75px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;

    &_item {
      font-weight: 700;
      font-size: 20px;
      padding: 12px 36px;
      background: linear-gradient(92.97deg, #0F2467 0%, #1D5CAC 100%);
      border-radius: 32px;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    &_item.active {
      background: linear-gradient(92.97deg, #0F2467 0%, #1D5CAC 100%);
      box-shadow: 0px 0px 15px #00ABFF;
      border-radius: 32px;
    }
  }

  &_security {
    &_contacts {
      margin-top: 50px;
      padding: 50px;
      background: #252C40;
      border-radius: 20px;

      &_item {
        margin: 30px 0;
        width: 90%;
        color: #fff;
        display: grid;
        grid-template-columns: 10% 40% 30% 30%;
        height: fit-content;
        align-items: center;
        gap: 10px;

        button {
          display: flex;
          align-self: flex-end !important;
          margin: 0 auto;
        }

        div {
          display: flex;
          align-self: flex-end !important;
          margin: 0 auto;
        }

        img {
          display: flex;
          align-self: flex-end !important;
          margin: 0 auto;
        }
      }

      &_title {
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        color: #FAFCFD;
        margin-bottom: 50px;
      }
    }

    &_password {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 50px;
      background: #252C40;
      border-radius: 20px;

      &_left {
        width: 50% !important;
      }

      &_right {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        img {
          width: 220px;
        }

        div {
          margin-top: 25px;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          display: flex;
          align-items: center;
          color: #FAFCFD;
        }

      }

      &_title {
        color: #FAFCFD;
        font-weight: 700;
        font-size: 24px;
      }
    }
  }

  &_profile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 50px 70px;
    background: #252C40;
    border-radius: 20px;

    &_photo div {
      background-size: cover;
    }

    &_right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
    }

    &_item {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &_referral {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
    }

    img {
      border-radius: 50%;
    }
  }

  &_wrapper {
    margin: 0 0 50px 390px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
  }

  margin-top: 0 !important;
}

@media (max-width: 1400px) {
  .settings {
    width: 95%;
  }

  .settings_wrapper {
    margin: 0 0 50px 48px
  }
}

@media (max-width: 600px) {
  .settings {
    .hide-small {
      display: none;
    }

    .passport_upload {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    box-sizing: border-box !important;

    .upload-photo-input {
      width: fit-content;
    }
  }

  .settings_sections {
    width: 90%;
    display: flex;
    align-self: center;
    justify-self: center;
    box-sizing: border-box;
    flex-direction: column;
    margin: 24px auto 48px;

    div {
      width: 80%;
      margin: 12px 0;
    }

    div:first-child {
      margin-top: 0;
    }

    div:last-child {
      margin-bottom: 0;
    }
  }

  .settings_documents_content {
    flex-direction: column;

    .settings_documents_item_left, .settings_documents_item_right {
      width: 100%;
    }

    .settings_documents_item_right {
      margin-left: 0;
    }

    input {
      width: 100%;
    }
  }

  .settings {
    width: 100%;
  }

  .settings_profile {
    width: calc(100% - 40px);
    margin-left: 0px;
    padding: 20px;
    flex-direction: column;
  }

  .settings_security {
    width: 100%;
    margin-left: -32px;
    padding: 32px;
    flex-direction: column;
  }

  .settings_security_password {
    flex-direction: column
  }

  .settings_security_password_left {
    width: 100% !important;
    margin-bottom: 32px;
  }

  .settings_profile_right {
    width: 100%;
  }

  .settings_profile_photo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 280px;
    max-height: 280px;
    width: 204px;
    height: 204px;
    margin: 0 auto;

    &_image {
      display: flex;
      align-self: center;
      justify-self: center;
      width: 100%;
      height: 100%;
    }
  }

  .settings_security_contacts_item {
    margin: 24px 12px;
    display: flex;
    flex-direction: column;
  }

  .settings_security_btn {
    font-size: 12px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .settings_security_contacts {
    padding: 24px;
  }

  .settings_documents_header {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;

    select {
      margin: 0;
      padding: 12px 12px;
      font-size: 16px;
    }

    select:first-child {
      margin: 0;
      width: fit-content;
    }

    select:last-child {
      width: fit-content;
    }
  }

  .settings {
    padding: 24px;
  }

  .settings_documents_content {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  .settings_documents_content input {
    width: 80% !important;
  }

  .settings_documents_item {
    width: 100%;
    box-sizing: border-box !important;
    padding: 16px;
  }

  .settings_sections_item {
    font-size: 12px;
  }

  .settings_documents_header select {
    font-size: 12px;
  }

  .settings_documents_title {
    font-size: 18px !important;
  }

  .settings_documents_content label {
    font-size: 12px !important;
  }

  .settings {
    padding: 12px;
  }

  .settings_security_password {
    padding: 24px 16px;
  }
}