.goal {
  margin: 80px 0 0;
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 40%;
  }

  &_item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    img {
      width: 80%;
    }

    &-title {
      text-transform: uppercase;
      background: linear-gradient(88.43deg, #53CDEF 0%, #467CD7 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    &-text {
      font-style: normal;
      font-weight: 400;
      font-size: 19px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #005395;
      text-align: right;
    }

    &:first-child {
      align-items: flex-start;
      margin-left: 32%;

      img {
        margin-top: 20px;
      }

      .goal_item-text {
        text-align: left;
      }
    }

    &:first-child {
      margin-bottom: 120px;
      img {
        margin-bottom: 80px;
      }
    }
  }
}

@media (max-width: 960px) {
  .goal_item:first-child {
    flex-direction: column !important;
  }

  .goal_wrapper {
    margin-top: -40px;
    background-image: none !important;
  }

  .goal_item-title {
    font-size: 24px !important;
  }

  .goal_item-text {
    font-size: 14px !important;
  }

  .goal_item:last-child {
    margin-top: -60px;
    margin-bottom: 80px !important;
  }
}