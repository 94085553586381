@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;700&family=Lato:wght@300;400;700&display=swap');
@font-face {
    font-family: 'aqum';
    src: url("./assets/font/aqum-2-small-caps.otf");
}

body {
    background: #FAFBFD;
  margin: 0;
    font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="checkbox"] { -webkit-appearance: checkbox; }
input[type="radio"] { -webkit-appearance: radio; }
input[type="submit"], input[type="button"] { -webkit-appearance: button; }
select { -webkit-appearance:textfield; }

::-webkit-scrollbar {
    width: 0;
    background-color: #3F87F6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    text-decoration: none;
    color: #fff;
}

button:hover {
     cursor: pointer;
     opacity: 0.8;
}