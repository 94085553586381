.admin-charges {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #FAFCFD;

  .charges_table_item_select.confirmed {
    opacity: 0.5;
    pointer-events: none;

    &:hover {
      cursor: not-allowed;
    }
  }

  &_title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &_btn {
      background: #0f2467;
      color: #FAFCFD;
      padding: 4px 20px;
      border-radius: 12px;

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        opacity: 0.5;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }

  .charges_table_header {
    color: #FFFFFF !important;
  }

  .charges_table_item {
    position: relative;
    color: #FFFFFF !important;

    div {

      color: #FFFFFF !important;
      opacity: 1 !important;
    }
  }

  &_wrapper {
    padding: 50px;
    width: 70%;
    margin: 0 0 0 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background: #171A2F;
    border-radius: 30px;
  }
}

@media (max-width: 525px) {
  .admin-charges {
    width: 100%;

    .charges_table_item {
      grid-template-columns: 2% 14% 15% 10% 10% 12% 12% 10% !important;;
    }

    &_wrapper {
      overflow: scroll;
      margin: 16px;
      width: 100%;
      padding: 16px;
    }

    .admin-charges_table {
      width: 150%;
    }

    &_item {
      gap: 12px;
      padding: 16px;

      &_full {
        max-width: fit-content !important;

        div {
          font-size: 8px !important;
        }
      }

      &_cont {
        gap: 16px;
        align-items: flex-start !important;
        display: flex !important;
        flex-direction: column !important;
        width: 100%;
      }

      display: flex;
      flex-direction: column;
    }
  }
}
