.photo-full-size {
  position: absolute;
  left: 30%;
  width: 440px !important;
  height: auto !important;
  transition: 0.3s;
}


.verification {
  &_photoFullSize {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1000000 !important;
  }

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #FAFCFD;

  .colored {
    background: linear-gradient(87.57deg, #3F87F6 0%, #4CE9C1 94.44%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 20px;
  }

  &_item {
    font-size: 14px;
    position: relative;
    padding: 50px 50px 75px 25px;
    margin: 25px 0;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #252C40;
    border-radius: 20px;

    &_photos {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: flex-start;
      height: fit-content;
      width: 60%;
      margin-top: 24px;

      img {
        margin-right: 12px;
        width: 40%;
      }
    }

    &_content {
      width: 100%;
      display: grid;
      grid-template-columns: 25% 20% 27% 28%;
      gap: 20px;
    }

    &_photo {
      box-sizing: border-box;
      margin-left: 12px;
      width: calc(100% - 12px);

      img {
        border-radius: 12px;
        width: 100%;
        cursor: pointer;
      }
    }

    &_controls {
      position: absolute;
      bottom: 20px;
      right: 20px;

      button {
        padding: 4px 16px;
        color: #FAFCFD;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        margin-left: 12px;
      }

      &_approve {
        background: linear-gradient(87.57deg, #3F87F6 0%, #4CE9C1 94.44%);
        border-radius: 32px;
      }

      &_reject {
        background: linear-gradient(87.57deg, #3F87F6 0%, #E94C4C 94.44%);
        border-radius: 32px;
      }
    }

    &_email {
      max-width: 100% !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }

  &_title {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 20px;
  }

  &_wrapper {
    padding: 50px;
    width: 70%;
    margin: 0 0 0 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background: #171A2F;
    border-radius: 30px;
  }
}

@media (max-width: 600px) {
  .verification_wrapper {
    margin: 0 8%;
  }

  .verification_item {
    width: 80%;
    display: flex;
    flex-direction: column;

    &_content {
      display: flex;
      flex-direction: column;
    }
  }
}

@media (max-width: 450px) {
  .verification_wrapper {
    margin: 0 8%;
    width: 90%;
    padding: 20px;
  }

  .verification_item {
    padding: 25px  25px 50px;
    width: 80%;
    display: flex;
    flex-direction: column;

    &_content {
      display: flex;
      flex-direction: column;
    }

    &_photos {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 20px;

      img {
        width: 50%;
      }
    }
  }
}
