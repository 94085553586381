@keyframes loyal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}



.structure_row::-webkit-scrollbar-track {
  padding: 2px 0;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}

.hide_scroll_bar::-webkit-scrollbar {
  background: none;
  display: none;
}

.structure_row::-webkit-scrollbar {
  width: 2px;
  background: #0f2467;
  border-radius: 12px;
}

.structure_row::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background: linear-gradient(88.43deg, #53CDEF 0%, #467CD7 100%);
}

.structure {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  animation: loyal 0.5 ease-in-out;

  &_close {
    cursor: pointer;
    transition: 0.3s;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 4px 12px 6px;
    height: auto;

    &:hover {
      opacity: 0.8;
    }
  }

  &_titleRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
  }

  &_wrapper {
    box-sizing: border-box !important;
    margin: 0 0 0 360px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    background: #171A2F;
    border-radius: 30px;
    padding: 50px;
    min-height: 100vh;
  }

  &_title {
    font-size: 28px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 20px;
  }

  &_row {
    justify-content: space-between;
    animation: loyal 0.5 ease-in-out;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 920px;
    overflow-x: scroll;
  }
}

@media (max-width: 1400px) {
  .structure_wrapper {
    margin: 0 0 0 82px;
  }
}

@media (max-width: 600px) {
 .structure_wrapper {
   position: absolute;
   left: 0;
   width: calc(100% - 52px);
   margin: 0 8%;
   overflow: scroll;
   padding: 18px;
   box-sizing: border-box;
 }
}