.contacts {
  display: flex;
  flex-direction: column;
  width: 1200px;
  margin: 40px 0 40px;

  .block-right {
    margin-left: auto;
  }

  &_hash-image {
    top: 0;
    right: 0;
  }

  &_email-image {
    bottom: 0;
    left: 0;
    z-index: 0;
    border-bottom-left-radius: 50%;
  }

  &_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    object-fit: contain;
  }

  &_sally {
    z-index: 0;
    position: absolute;
    bottom: 0;
    transform: translateY(40%);
    left: 20%;
    width: 700px;
  }

  &_block {
    display: flex;
    flex-direction: column;
    width: 360px;
    height: 300px;
    padding: 64px 50px;
    color: #FFF;
    position: relative;
    background: linear-gradient(157.26deg, rgba(37, 59, 121, 0.8) 10.6%, #4486EB 91.3%);
    box-shadow: 10px 10px 10px rgba(19, 22, 44, 0.5), inset -5px -5px 5px rgba(224, 235, 239, 0.25);
    border-radius: 50px;
    margin-top: 20px;

    &-btn {
      margin-top: 40px;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      padding: 12px 48px;
      text-transform: uppercase;
      background: linear-gradient(92.97deg, #0F2467 0%, #1D5CAC 100%);
      box-shadow: 2px 2px 10px rgba(0, 33, 74, 0.25), inset -2px -2px 10px rgba(0, 33, 74, 0.25), inset 2px 2px 10px rgba(250, 252, 253, 0.25);
      border-radius: 100px;
      transition: .3s;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    &-title {
      font-size: 32px;
    }

    &-title-center {
      font-size: 32px;
      text-align: center;
    }

    &-text {
      margin-top: 40px;
      align-self: center;
      width: 65%;
      font-size: 16px;
      text-align: center;
    }

    &-phones {
      font-size: 18px;
      margin-top: 32px;
    }

    &-email {
      font-size: 18px;
      margin-top: 120px;
      padding-left: 180px;
      z-index: 1;
    }

    &-links {
      margin-top: 40px;
      align-self: center;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      width: 70%;

      img {
        width: 21%;
        transition: .3s;
        cursor: pointer;
        position: initial !important;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    img {
      position: absolute;
    }
  }
}

@media (max-width: 1200px) {
  .contacts {
    width: 100%;

    &_wrapper {
      background-position: 0%;
      box-sizing: border-box;
      padding: 0 20px;
    }
  }

  .contacts_block {
    z-index: 2;
    width: 240px;
    height: 200px;

    .contacts_email-image, .contacts_hash-image {
      width: 30%;
    }

    .contacts_block-email {
      margin-top: 20px;
      padding-left: 80px;
    }
  }

  .contacts_sally {
    width: 600px;
  }
}

@media (max-width: 1020px) {
  .contacts_block-title-center {
    font-size: 18px;
  }

  .contacts_block-text {
    margin-top: 20px;
  }

  .contacts_block-links {
    margin-top: 20px;
  }

  .contacts_block-btn {
    margin-top: 20px;
    font-size: 12px;
  }

  .contacts_sally {
    display: none;
  }

  .contacts {
    flex-direction: row;
  }

  .contacts_block {
    font-size: 14px;
    padding: 48px 32px 72px;
  }
}

@media (max-width: 960px) {
  .contacts_block-email {
    font-size: 16px;
  }

  .contacts .block-right {
    margin: 80px 0 !important;
  }

  .contacts {
    padding: 0 12px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .contacts_wrapper {
    background-size: 300% 150%;
  }

}