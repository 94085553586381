*:not(input) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.confirm_modal {
  border-radius: 10px;

  .marketing_modal_title {
    font-size: 24px;
    text-align: center;
  }

  .marketing_modal_text {
    padding: 24px 0;
    width: 100%;
    max-width: 420px;
    text-align: justify;
  }
}

.marketing {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_modal_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    backdrop-filter: blur(3px);
  }

  &_modal {
    position: relative;
    color: #FAFCFD;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background: linear-gradient(157.26deg, #13162C 10.6%, #09156B 91.3%);
    padding: 50px;
    box-sizing: border-box !important;

    &_buy-title {
      font-size: 18px;
      margin: 20px auto 0;
    }

    &_btns {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      button {
        width: 45%;
        color: #FAFCFD;

        &:disabled {
          opacity: 0.5;
        }

        &:disabled:hover {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }

    &_close {
      position: absolute;
      right: 32px;
      top: 24px;
      font-size: 18px;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    img {
      position: absolute;
      bottom: 16px;
      align-self: center;
      width: 32px
    }

    &_btn {
      margin-top: 20px;
      font-size: 20px;
      padding: 12px 24px;
      align-self: center;
      background: linear-gradient(91.97deg, #049CE4 0.3%, #80ECDF 99.83%);
      box-shadow: 4px 4px 4px rgba(0, 33, 74, 0.25), inset -4px -4px 4px rgba(0, 33, 74, 0.25), inset 4px 4px 4px rgba(255, 255, 255, 0.25);
      border-radius: 100px;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    &_title {
      align-self: center;
      margin: 0 0 20px;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }

    &_text {
      margin: 12px 0;
    }

    &_agreement {
      z-index: 1000 !important;
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-weight: 400;
      -webkit-appearance: checkbox;

      input {
        width: 20px;
        height: 20px;
        -webkit-appearance: checkbox;
        z-index: 1500 !important;
      }

      a {
        font-weight: 900;
        font-size: 14px;
      }
    }

    label {
      padding-left: 12px;
      width: 400px;
      font-weight: 400;
      font-size: 12px;
    }
  }

  &_wrapper {
    width: 75%;
    margin: 50px 0 50px 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  &_item {
    margin-bottom: 50px;
    z-index: 10;
    background: #171A2F;
    border-radius: 30px;
    position: relative;
    padding: 75px 0 125px 75px;
    height: 390px;

    &_price {
      margin-left: 50px;
      font-weight: 700;
      font-size: 36px;
    }

    &_buy {
      color: #FAFCFD;
      padding: 18px 36px;
      font-size: 24px;
      font-weight: 700;
      background: linear-gradient(91.97deg, #049CE4 0.3%, #80ECDF 99.83%);
      box-shadow: 4px 4px 4px rgba(0, 33, 74, 0.25), inset -4px -4px 4px rgba(0, 33, 74, 0.25), inset 4px 4px 4px rgba(255, 255, 255, 0.25);
      border-radius: 100px;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.8
      }

      &:disabled {
        color: #FAFCFD;
        cursor: not-allowed;
        opacity: 0.5;
      }

      &:disabled:hover {
        color: #FAFCFD;
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    &_footer {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &_content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #ffffff;
      z-index: 10;
    }

    &_title {
      background: linear-gradient(88.43deg, #53CDEF 0%, #467CD7 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 36px;
      font-weight: 700;
      z-index: 5;
    }

    &_description {
      margin-top: 20px;
      font-size: 20px;
      font-weight: 700;
      z-index: 5;

      &-item {
        margin-bottom: 12px;
      }
    }

    &_image {
      width: 400px;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &_background {
      width: calc(100% - 50px);
      z-index: -1;
      position: absolute;
      top: 25px;
      left: 25px;
    }
  }
}

@media (max-width: 1400px) {
  .marketing_wrapper {
    margin: 0 0 50px 24px;
  }

  .marketing_item {

    &_background {
      display: none;
    }

    img {
      width: 40%;
    }
  }
}

@media (max-width: 600px) {
   .marketing {
     width: 100%;
     margin-left: -24px;

     &_modal_wrapper {
       width: 100%;
     }

     &_modal {
       width: calc(100% - 16px);
       padding: 12px;

       &_btns {
         button {
           font-size: 14px;
         }
       }

       &_title {
         font-size: 16px;
       }

       &_text {
         font-size: 14px;
       }

       label {
         width: 100%;
         font-size: 10px;

         a {
           font-size: 12px;
         }
       }
     }
   }

  .marketing_item {
    width: 100%;
    padding: 24px;

    img {
      border-bottom-right-radius: 24px;
    }
  }

  .marketing_item_title {
    height: 100%;
    margin-bottom: 32px;
    font-size: 20px;
  }

  .marketing_item_description {
    font-size: 10px;
  }

  .marketing_item_buy {
    font-size: 10px;
    font-weight: 700;
    padding: 12px;
  }

  .marketing_item_footer {
    margin-top: 32px;
    margin-bottom: 90px;
  }

  .bigger-margin {
    margin-bottom: 110px;
  }

  .marketing_item_price {
    font-size: 20px;
  }
}
