.admin_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .structure_wrapper {
    max-width: 1040px;
  }
}