.footer {
  margin: 40px 0;
  width: 1200px;
  display: flex;
  flex-direction: column;
  z-index: 10;

  &_form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 480px;

    &_item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-right: 8px;

      input {
        width: 50%;
      }
    }

    div input:last-child {
      margin-left: 8px;
    }
  }

  &_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0A0E1C;
  }

  &_link {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    transition: .3s;

    a {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 18px;
      }
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8;
      background-color: rgba(10, 14, 28, 0.85);
    }
  }

  &_content {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &_navigation {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #ffffff;

      &-header {
        font-size: 24px;
        margin-bottom: 32px;
      }

      &-item {
        margin: 12px 0;
        transition: .3s;

        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }

        span {
          margin-right: 32px;

          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }
      }
    }

    &_left {
      display: flex;
      flex-direction: column;

      &-text {
        margin-top: 16px;
        color: #fff;
        width: 320px;
        font-size: 20px;
      }

      &-links {
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  &_info {
    margin-top: 40px;
    padding: 24px 48px;
    background-color: #222A2C;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div:first-child {
      color: #fff;
      width: 38%;
      font-size: 14px;
    }

    div:last-child {
      span {
        color: #fff;
        margin: 0 10px;
      }
    }
  }

  &_line {
    width: 100%;
    height: 1px;
    background-color: #00396D;
  }

  &_header {
    padding: 40px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    input {
      background: #FFFFFF;
      border-radius: 10px;
      padding: 24px;
      border: none;
      outline: none;
    }

    &-text {
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
      width: 50%;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #EFFFFF;
    }

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      background: linear-gradient(134.32deg, #00FFC2 -1.73%, #00FFFF 103.04%);
      border-radius: 10px;
      transition: .3s;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }

      img {
        width: 70%;
      }
    }
  }
}

@media (max-width: 1200px) {
  .footer {
    width: 100%;

    &_wrapper {
      padding: 0 24px;
    }
  }
}

@media (max-width: 900px) {
  .footer_content_left-links {
    margin-top: 16px;
  }

  .footer_header-btn {
    align-self: flex-end;
  }

  .footer_content_navigation {
    width: 100%;
    margin: 12px 0;
  }

  .footer {
    &_content_navigation-item {
      font-size: 14px;
    }

    &_wrapper {
      width: 100%;
      box-sizing: border-box !important;
    }

    &_content {
      box-sizing: border-box !important;
      width: 100%;
    }

    &_info {
      flex-direction: column;
      width: calc(100% - 96px);

      div:first-child {
        width: 100%;
      }

      div:last-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-self: center;
        width: 100%;
        margin-top: 20px;
        font-size: 12px;
      }
    }

    &_content {
      flex-direction: column;
      justify-content: flex-start;
      align-self: flex-start;
      padding: 0 24px;
    }

    &_line {
      margin-top: 8px;
    }

    &_header {
      box-sizing: border-box !important;
      padding: 0 24px;
      width: 100%;
      flex-direction: column;
      display: flex;

      &_header-btn {
        display: flex;
        align-self: flex-end;
        justify-self: flex-end;
      }

      &-text {
        font-size: 24px;
        width: 100%;
        text-align: center;
      }

      input {
        width: 100%;
        margin: 8px 0;
      }
    }
  }
}

@media (max-width: 900px) {
  .footer_header-text {
    font-size: 16px;
    width: 100%;
    line-height: 24px;
  }

  .footer_form {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div input:last-child {
      margin-left: 0;
    }

    input {
      height: 4px;
      padding: 14px;
    }

    div:first-child {
      display: flex;
      flex-direction: column;
      width: 220px;
      margin-right: 42px;
    }

    div:last-child {
      width: 92px;
      height: 92px;
    }
  }

  .footer_content_left {
    width: 100%;
    max-width: 320px !important;
    justify-content: center;
    align-items: center;

    div {
      text-align: center;
    }
  }

  .footer_content_navigation {
    margin-top: 40px;
  }

  .footer_info_links {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    margin-bottom: 24px;

    span {
      font-size: 18px;
    }
  }

  .footer_info {
    flex-direction: column-reverse;
  }
}